<template>
  <ObsidianAppShell v-if="whatToShow === 'appshell'" @user-logged-out="userLoggedOut" />
  <main
    v-if="whatToShow === 'viewOnly'"
    class="flex-1 flex bg-gray-50 overflow-hidden h-full w-full"
  >
    <router-view @user-logged-in="userLoggedIn" />
  </main>

  <main
    v-if="whatToShow === 'emptyPage'"
    class="flex-1 flex bg-gray-50 overflow-hidden h-full w-full"
  />

  <TheAppNotification :notification-store="NotifyCenter.getStore()" />
  <ApiDeployErrorDialog />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { TheAppNotification } from '@apiida/vue-components';
import { logVersions } from '@apiida/controlplane-api-helper';
import { useRoute, useRouter } from 'vue-router';
import ObsidianAppShell from './components/app/ObsidianAppShell.vue';
import NotifyCenter from './services/NotifyCenter';
import apiClient from './services/ApiClient';
import config from './config';
import { insertSubdomain } from './helper/TenantHelper';
import AuthService from './services/app/AuthService';
import isAllowedPath from './helper/RouterHelper';
import ApiDeployErrorDialog from './components/api/ApiDeployErrorDialog.vue';

export default defineComponent({
  name: 'App',
  components: {
    ApiDeployErrorDialog,
    TheAppNotification,
    ObsidianAppShell,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const whatToShow = ref<'appshell' | 'viewOnly' | 'emptyPage'>('emptyPage');

    async function userLoggedIn() {
      await router.push('/welcome');
      whatToShow.value = 'appshell';
    }

    async function userLoggedOut() {
      await router.push('/auth');
      whatToShow.value = 'viewOnly';
    }

    async function checkWhatToShow() {
      // An allowed path should not be see the appshell.
      if (AuthService.isThereAToken() && !isAllowedPath(route.path)) {
        const userCouldBeLoaded = await AuthService.loadcurrentUser(false);
        if (userCouldBeLoaded) {
          whatToShow.value = 'appshell';
        }
      } else {
        whatToShow.value = 'viewOnly';
      }
    }

    onMounted(async () => {
      whatToShow.value = 'emptyPage';
      logVersions(
        apiClient,
        {
          // eslint-disable-next-line no-undef
          name: String(APP_NAME),
          // eslint-disable-next-line no-undef
          version: config.version,
          commit: String(import.meta.env.VITE_GITHUB_SHA),
          buildTime: String(import.meta.env.VITE_BUILD_TIME),
        },
        insertSubdomain(config.backendUrl),
      );

      // When the token expires on the backend page, we can get stuck in the "emptyPage". So we check that again after a sec
      checkWhatToShow();
      setTimeout(() => {
        checkWhatToShow();
      }, 200);
      setTimeout(() => {
        checkWhatToShow();
      }, 1000);

      // if back button is pressed
      window.onpopstate = () => {
        checkWhatToShow();
      };
    });

    return { NotifyCenter, whatToShow, userLoggedIn, userLoggedOut };
  },
});
</script>
