import config from '../config';

/**
 * The subdomain can now differ from the tenantId.
 * The subdomain is still in the URL and is inserted into the backend URL.
 * Inserts the subdomain into the backend url.
 * @param url
 * @param localhostDevInsert Only interesting for local development. The .dev is mostly used because of the TLS certificates.
 */
export function insertSubdomain(url: string, localhostDevInsert: boolean = true): string {
  let tmpUrl = url;

  // do not change this. see readme
  if (!config.insertTenantId.includes('addTenantIdToBack')) {
    return tmpUrl;
  }

  const subdomain = window.location.host.split('.')[1]
    ? `${window.location.host.split('.')[0]}.`
    : undefined;

  if (subdomain) {
    const urlParts = url.split('://');

    // If we develop local in SaaS mode, we have to add the subdomain dev, because you cannot issue certificates for *.localhost.
    if (urlParts[1].includes('localhost:') && localhostDevInsert) {
      urlParts[1] = `dev.${urlParts[1]}`;
    }

    tmpUrl = `${urlParts[0]}://${subdomain}${urlParts[1]}`;
  }

  return tmpUrl;
}
