import { TierEnum } from '@apiida/controlplane-api-helper';
import environmentConfig from './environmentConfig';

const config = () => ({
  version: environmentConfig.version,
  backendUrl: environmentConfig.backendUrl,
  backendWs: environmentConfig.backendWs,
  reloadAgentStatus: environmentConfig.reloadAgentStatus,
  supportUrl: environmentConfig.supportUrl,
  salesUrlMeeting: environmentConfig.salesUrlMeeting,
  aiTermBoomiPlatform: environmentConfig.aiTermBoomiPlatform,
  agentImage: environmentConfig.agentImage,
  insertTenantId: environmentConfig.insertTenantId,
  enableTierAndBilling: false, // TierRouter and ConfigurationRouter -> Commented out code

  tinyEditorApiKey: environmentConfig.tinyEditorApiKey, // entwickler@apiida - Do not change this

  // Booleans cannot be set by environment variable with the current procedure.
  mockMetrics: false, // An environment variable must be set in the backend.
  allowDarkMode: false,
  allowTutorial: true,

  allowPlatformMuleSoft: false, // Sven will finish Agent soon
  allowPlatformIBM: false, // not ready and not planned
  allowPlatformBoomiCAM: true, // Boomi Cloud API Management (Mashery) coming soon
  allowPlatformApigeeX: false, // coming soon

  allowAiDocGeneration: true, // coming soon

  // It's just a joke. Please use locally only.
  allowLetItSnow: false, // let it snow! https://github.com/Fuxy526/snowf?tab=readme-ov-file#default-options

  // tier pirces
  getTier: (
    tier: TierEnum,
  ): { name: string; price: number; apis: number; applications: number } => {
    switch (tier) {
      case TierEnum.GROWTH:
        return { name: 'Growth', price: 975, apis: 30, applications: 50 };
      case TierEnum.PROFESSIONAL:
        return { name: 'Professional', price: 2450, apis: 90, applications: 150 };
      default:
        return { name: 'Enterprise', price: 0, apis: 0, applications: 0 };
    }
  },
  getSmallPackage: (
    tier: TierEnum,
  ): { name: string; price: number; apis: number; applications: number } => {
    switch (tier) {
      case TierEnum.GROWTH:
        return { name: 'Small Package', price: 400, apis: 10, applications: 15 };
      case TierEnum.PROFESSIONAL:
        return { name: 'Small Package', price: 325, apis: 10, applications: 15 };
      default:
        return { name: 'Small Package', price: 0, apis: 0, applications: 0 };
    }
  },
  getLargePackage: (
    tier: TierEnum,
  ): { name: string; price: number; apis: number; applications: number } => {
    switch (tier) {
      case TierEnum.GROWTH:
        return { name: 'Large Package', price: 750, apis: 25, applications: 40 };
      case TierEnum.PROFESSIONAL:
        return { name: 'Large Package', price: 500, apis: 25, applications: 40 };
      default:
        return { name: 'Large Package', price: 0, apis: 0, applications: 0 };
    }
  },
  getTotalPrice: (tier: TierEnum, smallPackages: number, largePackages: number): number => {
    const tierPrice = config().getTier(tier).price;
    return (
      tierPrice +
      config().getSmallPackage(tier).price * smallPackages +
      config().getLargePackage(tier).price * largePackages
    );
  },
});
// Also create an entry in setEnvVarsOnContainerStartup.sh if it is a string to be set via an EnvVar!

export default config();
