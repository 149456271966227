import { ApiClientHelper, TierEnum } from '@apiida/controlplane-api-helper';
import mainStore from '../../stores/MainStore';
import ApiClient from '../ApiClient';
import SaaSInfo from '../../types/app/SaaSInfo';
import TierLimits from '../../types/app/TierLimits';
import Environment from '../../types/environments/Environment';
import DevPortal from '../../types/configuration/DevPortal';
import { Status } from '@/types/enums/Status';
import ConfigurationService from '../configuration/ConfigurationService';

export default {
  getMainStore(): any {
    // @ts-ignore
    return mainStore(this.$pinia);
  },
  async loadSaaSInfo(): Promise<SaaSInfo> {
    const response = await ApiClient.get<SaaSInfo>('saasInfo', true);

    const saasInfo: SaaSInfo =
      response?.data ??
      ({
        isSaaS: false,
        tierInfo: { tier: TierEnum.ENTERPRISE },
        linkedWithBoomi: false,
      } as SaaSInfo);
    this.getMainStore().SaaSInfo = saasInfo;

    return saasInfo;
  },
  async loadInitialGeneralInformation(): Promise<void> {
    const response = await ConfigurationService.getGeneralConfiguration();

    if (response !== null) {
      this.getMainStore().config = response;
    }
  },
  async getTierLimits(): Promise<TierLimits | undefined> {
    const response = await ApiClient.get<TierLimits>('tierLimits');

    return response?.data;
  },
  async hideTierDowngradeMessage(): Promise<boolean> {
    const response = await ApiClient.post('hideTierDowngradeMessage');

    return ApiClientHelper.isSuccess(response);
  },
  async setInitialStatus(data: Environment | DevPortal) {
    const limits = await this.getTierLimits();

    if (limits === undefined) {
      data.status = Status.ACTIVE;
      return;
    }

    if (limits.devPortalLimit === null || limits.devPortalCount < limits.devPortalLimit) {
      data.status = Status.ACTIVE;
    } else {
      data.status = Status.INACTIVE;
    }
  },
};
