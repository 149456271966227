import { OrganizationType, User, UserRole } from '@apiida/controlplane-api-helper';
import mainStore from '../../stores/MainStore';
import OrganizationService from '../../services/configuration/OrganizationService';

function getCurrentUser(): User {
  let user = mainStore().loggedInUser;

  // If the user is not defined, we simply load him.
  if (user.role === undefined) {
    user = { role: [] } as unknown as User;
  }
  return user;
}

export default {
  getRole(): UserRole {
    if (getCurrentUser().role === UserRole.billingcontact) {
      return UserRole.billingcontact;
    }
    if (getCurrentUser().role === UserRole.user) {
      return UserRole.user;
    }
    if (getCurrentUser().role === UserRole.admin) {
      return UserRole.admin;
    }
    if (getCurrentUser().role === UserRole.globaladmin) {
      return UserRole.globaladmin;
    }
    if (getCurrentUser().role === UserRole.noAccess) {
      return UserRole.noAccess;
    }
    return UserRole.user;
  },
  isBillingContact(): boolean {
    return getCurrentUser().role === UserRole.billingcontact;
  },
  isUser(): boolean {
    return getCurrentUser().role === UserRole.user;
  },
  isAdmin(): boolean {
    return getCurrentUser().role === UserRole.admin;
  },
  isGlobalAdmin(): boolean {
    return getCurrentUser().role === UserRole.globaladmin;
  },
  isNoAccess(): boolean {
    return getCurrentUser().role === UserRole.noAccess;
  },
  isAdminOrGlobalAdmin(): boolean {
    const user = getCurrentUser();
    return user.role === UserRole.globaladmin || user.role === UserRole.admin;
  },

  isGlobalAdminOrBilling(): boolean {
    const user = getCurrentUser();
    return user.role === UserRole.globaladmin || user.role === UserRole.billingcontact;
  },

  isAdminOrGlobalAdminOrBilling(): boolean {
    const user = getCurrentUser();
    return (
      user.role === UserRole.globaladmin ||
      user.role === UserRole.admin ||
      user.role === UserRole.billingcontact
    );
  },

  async isLoginUserPublishingOrg(): Promise<boolean> {
    const user = getCurrentUser();
    if (user.organization) {
      const userOrganization = await OrganizationService.get(user.organization);
      if (userOrganization.type === OrganizationType.PUBLISHER) {
        return true;
      }
    }
    return false;
  },
};
